import React from 'react';
import logo from './logo.svg';
import './App.css';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const auth = new WebSocket('wss://georgeanthony.net:82')
const server = new WebSocket('wss://georgeanthony.net:83')

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  resetContainer: {
    padding: theme.spacing(3)
  }
}));

function App() {
    
  const classes = useStyles()

  const [state, setState] = React.useState({
    menu: false,
    user: false,
    token: false,
    numbers: [],
    message: '',
    tooLong: false
  })

  

  auth.onerror = function(error) {
    console.log('Connect Error: ' + error.toString())
  }
  
  auth.onopen = function(connection) {
    console.log('WebSocket Client Connected')

    if (localStorage.getItem('user') != null && localStorage.getItem('token') != null) {
      setState({ ...state, user: localStorage.getItem('user'), token: localStorage.getItem('token') })
    }
  
    auth.onmessage = function(message) {
      console.log(message)
        message = JSON.parse(message.data)
        
        switch(message[0]) {
          case 'success': {
            localStorage.setItem('user', JSON.stringify(message[2]))
            localStorage.setItem('token', JSON.stringify(message[1]))

            setState({ ...state, user: message[2], token: message[1] })

            handleLoginClose()
            console.log('LOGGED IN')
            break;
          }
        }
    }
  }
  
  auth.onclose = function() {
    console.log('Connection Closed')
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open })
  }  

  const [inboxOpen, setInboxOpen] = React.useState(false)
  const [loginOpen, setLoginOpen] = React.useState(false)
  const [accountOpen, setAccountOpen] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)

  const handleInboxClick = () => {
    setInboxOpen(!inboxOpen)
  }

  const handleLoginClose = () => {
    setLoginOpen(false)
  }

  const handleAccountClose = () => {
    setAccountOpen(false)
  }

  const handleLogin = (id,pass) => {
    auth.send(JSON.stringify([
      'login',
      id,
      pass
    ]))
  }

  const handleSend = (numbers, text) => {
    server.send(JSON.stringify([
      state['token'],
      JSON.parse(state['user']).email,
      'sm',
      [numbers,text,'+15162616031']
    ]))
  }

  const handleLogout = () => {
    setState({ ...state, user: false, token: false })
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    setAccountOpen(false)
  }

  const handleLoginOpen = () => {
    setLoginOpen(true)
  }
  const handleAccountOpen = () => {
    setAccountOpen(true)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const handleNumbers = (numbers) => {
    server.send(JSON.stringify([
      state['token'],
      JSON.parse(state['user']).email,
      'sn',
      [numbers,[]]
    ]))

    setState({ ...state, numbers:numbers === '' ? [] : numbers})
    console.log(numbers)

  }

  const Styles = {
    toolbar: {
      paddingLeft:0, 
      paddingRight:0
    },
    menuButton: {
      fontSize: '220%'
    },
    accountButton: {
      fontSize: '135%'
    },
    loginButton: {
      fontSize: '100%'
    },
    title: {
      fontSize:'150%'
    },
    body:{
      paddingTop: '50px',
      padding:'5px'
    },
    logoIMG:{
      marginTop:'50px'
    },
    numbers:{
      marginBottom:'25px',
      marginTop:'25px',
      background:'white',
      borderRadius:'5px'
    },
    Rnext: {
      background:'red'
    }
  }

  const Menu = 
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Sample Menu Items
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem button>
        <ListItemIcon>
          <Icon>send</Icon>
        </ListItemIcon>
        <ListItemText primary="Sent mail" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <Icon>drafts</Icon>
        </ListItemIcon>
        <ListItemText primary="Drafts" />
      </ListItem>
      <ListItem button onClick={handleInboxClick}>
        <ListItemIcon>
          <Icon>inbox</Icon>
        </ListItemIcon>
        <ListItemText primary="Inbox" />
        {inboxOpen ? <Icon>remove</Icon> : <Icon>add</Icon>}
      </ListItem>
      <Collapse in={inboxOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Icon>star</Icon>
            </ListItemIcon>
            <ListItemText primary="Starred" />
          </ListItem>
        </List>
      </Collapse>
    </List>

  const LoginPopup = 
    <Dialog open={loginOpen} onClose={handleLoginClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Login</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Welcome! New User? <a>Register Soon</a>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          defaultValue=''
          id="name"
          label="Email Address"
          type="email"
          fullWidth
        /><br/><br/>
        <TextField
          margin="dense"
          defaultValue=''
          id="password"
          label="password"
          type="password"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLoginClose} color="primary">
          Cancel
        </Button>
        <Button onClick={()=>handleLogin(document.getElementById('name').value,document.getElementById('password').value)} color="primary">
          Login
        </Button>
      </DialogActions>
    </Dialog>

  const AccountPopup = 
    <Dialog open={accountOpen} onClose={handleAccountClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Login</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Welcome {state['user'].email}
        </DialogContentText>
        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccountClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleLogout} color="primary">
          Logout
        </Button>
      </DialogActions>
    </Dialog>

    let BODY
    const steps = ['Load Numbers', 'Prepare Message']
    const stepContent = [
      <div><Typography>Welcome. Enter a list of numbers below, separated by spaces or commas, & press next to continue.</Typography>
        <TextField
          margin="dense"
          variant='filled'
          multiline
          rows={4}
          defaultValue=''
          id="numbers"
          label="Phone Numbers"
          type="number"
          fullWidth
          style={Styles.numbers}
        />
      </div>,<div>
        <Typography>Numbers loaded successfully! (I Hope) Now enter the message below (160 character limit).</Typography>
          <TextField
            margin="dense"
            variant='filled'
            multiline
            rows={4}
            defaultValue=''
            id="message"
            label="Text Message"
            type="text"
            maxLength="160"
            fullWidth
            onChange={(e) => {setState({...state, message: e.target.value, tooLong: e.target.value.length > 159 })}}
            style={Styles.numbers}
          />
        </div>]

    if (state['user'] && state['token']) {
      BODY = 
      <header className="App-header" style={Styles.body}>
        <img src={logo} className="App-logo" alt="logo" style={Styles.logoIMG}/>
        <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{stepContent[index]}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color='primary'
                    onClick={activeStep === 0 ? 
                      ()=>{handleNext(); handleNumbers(document.getElementById('numbers').value.replace(/,/, '').replace(/\n/g, " ").split(' ')); } : activeStep === 1 ?
                      ()=>{if (!state['tooLong']) {handleNext(); handleSend(state['numbers'], document.getElementById('message').value);} } :
                      ()=>{handleNext()}
                    }
                    className={classes.button}
                    style={state['tooLong'] ? Styles.Rnext : null}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>Message Sent Successfully (I hope)</Typography>
          <Button onClick={handleReset} color="primary" variant="contained" >
            Reset
          </Button>
        </Paper>
      )}
      </header>
    } else {
      BODY = 
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" style={Styles.logoIMG}/>
        <p>
          Log in & Send!
        </p>
        <Button color="primary" variant="contained" style={Styles.loginButton} marginRight={0} onClick={handleLoginOpen}>Login</Button>
      </header>
    }

  return (

    <div className="App">
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <AppBar position="fixed">
          <Toolbar style={Styles.toolbar}>
            <Button onClick={toggleDrawer('menu', true)} padding={0}><Icon style={Styles.menuButton}>menu</Icon></Button>
            <Drawer anchor={'left'} open={state['menu']} onClose={toggleDrawer('menu', false)}>
              <div style={{width:'300px'}}>{Menu}</div>
            </Drawer>
            <Typography variant="h6" className={classes.title} style={Styles.title}>
              Batch Text
            </Typography>
            <Button color="inherit" style={Styles.accountButton} marginRight={0} onClick={state['user'] ? handleAccountOpen : handleLoginOpen }><Icon style={Styles.accountButton}>person</Icon></Button>
            {LoginPopup}
            {AccountPopup}
          </Toolbar>
      </AppBar>
      {BODY}
    </div>
  );
}

export default App;
